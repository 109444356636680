<script>
import api from '@/modules/api'
import loading from '@/modules/loading'
import dialogo from '@/modules/dialogo'
import clone from '@/modules/clone'
import model from '@/model'
import Paginador from '@/components/Paginador.vue'
import EditRouteModal from "@/components/EditRouteModal.vue";

export default {
  name: "ListRoutesModal",
  props: {
    closeFunc: {
      type: Function,
      required: true,
    },
  },
  components: {
    Paginador,
    EditRouteModal,
  },
  data() {
    return {
      routes: undefined,
      paginador: clone(model.paginador),
    }
  },
  methods: {
    open() {
      document.getElementById('btn-modal-route-monitor-list-routes-abre').click();
    },

    close() {
      document.getElementById('btn-modal-route-monitor-list-routes-fecha').click();
    },

    search(resetPage) {
      const q = this.search.q;
      let params = this.paginador.requestParams;
      if (resetPage) {
        params = this.paginador.requestParamsFirst;
      }

      this.preSelecionado = undefined;
      loading(true);
      api.get(`/route-monitor/routes?${params}&orderBy[description]&q=${q}&with=Steps`).then(res => {
        loading(false);
        this.routes = res.data.items;
        this.paginador.pagina = res.data.page;
        this.paginador.totalItens = res.data.totalItems;
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    removeRoute(id) {
      loading(true);
      api.delete(`/route-monitor/routes/${id}`).then(() => {
        loading(false);
        this.search();
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    editRouteClosed() {
      this.open();
      this.search();
    },

    ///////////////////////////
    // ui
    css(route) {
      switch (true) {
        case (route.active):
          return ['text-info'];

        default:
          return ['text-muted'];
      }
    },

    btnSearchClick() {
      this.search(true);
    },

    onChangePaginacao() {
      this.search();
    },

    btnEditClick(route) {
      this.close();
      this.$refs.editRouteModal.open(route);
    },

    btnRemoveClick(route) {
      dialogo.confirmacao(`Confirma remover a rota <b>${route.description}</b>`).then(() => {
        this.removeRoute(route.id);
      });
    },
    // ui
    ///////////////////////////

  },
  mounted() {
    document.getElementById('modal-route-monitor-list-routes').addEventListener('hide.bs.modal', this.closeFunc);
  },
  created() {
    this.search();
  },
};
</script>

<style scoped>
.border-4px {
  border-left: 4px solid;
  /* border-radius: 4px; */
}
</style>

<template>
  <div>
    <!-- modal-route-monitor-list-routes -->
    <!-- Button trigger modal -->
    <button type="button" class="d-none" data-bs-toggle="modal" data-bs-target="#modal-route-monitor-list-routes" id="btn-modal-route-monitor-list-routes-abre">
      Launch
    </button>

    <!-- Modal -->
    <div class="modal fade" id="modal-route-monitor-list-routes" tabindex="-1" role="dialog" aria-labelledby="modalOnuInfo" aria-hidden="true">
      <div class="modal-dialog modal-xl modal-fullscreen-margin scroll" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              <font-awesome-icon :icon="['fas', 'cogs']" fixed-width/>
              Configurar rotas
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row g-0">
              <div class="col-12">
                <div class="form-group">
                  <label for="q">Pesquisa</label>
                </div>
                <div class="input-group">
                  <input type="text" class="form-control" name="q" id="q" placeholder="">
                  <span class="input-group-btn">
                    <button @click="btnSearchClick()" class="btn btn-secondary" type="button" aria-label="">
                      <font-awesome-icon :icon="['fas', 'search']" fixed-width/>
                    </button>
                  </span>
                </div>
              </div>
              <div class="col-12">
                <table class="table table-sm table-striped table-hover">
                  <thead class="thead-light">
                    <tr>
                      <th>Descrição</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="route in routes" :key="route.id" class="my-sm" :class="css(route)">
                      <td scope="row">
                        {{route.description}}
                      </td>
                      <td align="right">
                        <button type="button" @click="btnEditClick(route)" class="btn btn-sm btn-outline-primary">
                          <font-awesome-icon :icon="['fas', 'pen']" fixed-width/>
                          Editar
                        </button>
                        <button type="button" @click="btnRemoveClick(route)" class="btn btn-sm btn-outline-danger ms-1">
                          <font-awesome-icon :icon="['fas', 'trash']" fixed-width/>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="col-12">
                <Paginador v-model="paginador" @change-paginacao="onChangePaginacao"/>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" id="btn-modal-route-monitor-list-routes-fecha">Fechar</button>
          </div>
        </div>
      </div>
    </div>
    <!-- modal-route-monitor-list-routes -->

    <EditRouteModal :closeFunc="editRouteClosed" ref="editRouteModal" />
  </div>
</template>
