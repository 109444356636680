<template>
  <div class="config-integrations">
    <div class="row g-0">
      <div class="col">
        <h1 class="titulo-card fs-4">Monitor de rotas</h1>
      </div>
      <div class="col-auto">
        <div class="dropdown input-group">
          <button class="btn btn-sm btn-secondary dropdown-toggle" type="button" id="triggerDDAcoesOnu" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <font-awesome-icon :icon="['fas', 'bars']" fixed-width/>
          </button>
          <ul class="dropdown-menu dropdown-menu-primary" aria-labelledby="triggerDDAcoesOnu">
            <li>
              <button v-on:click="importRoutesClick()" type="button" class="dropdown-item">
                <font-awesome-icon :icon="['fas', 'file-import']" fixed-width/> Importar rotas
              </button>
            </li>
            <li>
              <button v-on:click="configRoutesClick()" type="button" class="dropdown-item">
                <font-awesome-icon :icon="['fas', 'cogs']" fixed-width/> Configurar rotas
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div v-if="initiated" class="form-group card-principal">
      <google-map id="map" ref="Map" class="w-100" :center="center" :zoom="zoom">
        <google-map-polyline
            v-for="route in routes"
            :key="route.id"
            :path="route.steps"
            :editable="false"
            :clickable="true"
            :geodesic="true"
            :strokeOpacity="1"
            :strokeWeight="route._strokeWeight"
            :strokeColor="routeColor(route)"
            @click="toggleInfoWindow(route, $event)"
            @mouseover="mouseOver(route)"
            @mouseout="mouseOut(route)"
        ></google-map-polyline>
        <google-map-infowindow
            :position="infoWindowContext.position"
            :show.sync="infoWindowContext.showInfo"
            :options="infoWindowContext.options"
        >
          <div>
            <h5>{{infoWindowContext.title}}</h5>
            <p class="mb-0" v-html="infoWindowContext.description"></p>
          </div>
        </google-map-infowindow>
      </google-map>
    </div>

    <ImportRouteModal :closeFunc="importRouteClosed" ref="importRouteModal" />
    <ListRoutesModal :closeFunc="listRouteClosed" ref="listRoutesModal" />
  </div>
</template>

<script>
import api from '@/modules/api'
import dialogo from '@/modules/dialogo'
import ImportRouteModal from "@/components/ImportRouteModal.vue";
import ListRoutesModal from "@/components/ListRoutesModal.vue";
import bytes from '@/modules/bytes'

export default {
  name: 'RouteMonitor',
  components: {
    ImportRouteModal,
    ListRoutesModal,
  },
  data() {
    return {
      initiated: false,
      map: null,
      infowindow: null,
      center: { lat: -16.3240331, lng: -56.2450763 },
      zoom: 4,
      routes: [],
      getDataInterval: undefined,

      infoWindowContext: {
        position: {
          lat: -18.9572,
          lng: -42.07691
        },
        showInfo: false,
        title: '',
        description: '',
        options: {
          maxWidth: 300,
        },
      },

      lineSymbol: {
        path: "M 0,-1 0,1",
        strokeOpacity: 1,
        scale: 4,
      },
    }
  },
  methods: {
    async initMap() {
      await api.get(`/configs/parameters`).then(res => {
        this.center.lat = +res.data.routeMonitorMapLat;
        this.center.lng = +res.data.routeMonitorMapLng;
        this.zoom = +res.data.routeMonitorMapZoom;
        this.initiated = true;
      }).catch(error => {
        dialogo.erroHttp(error);
      });
    },

    refreshRoutes() {
      this.routes = [];

      api.get('/route-monitor/routes?active=true&with=Steps&paginate.cancel').then(res => {
        res.data.items.forEach(x => {
          x.steps = x.steps.map(y => {
            return {
              lat: +y.lat,
              lng: +y.lng,
            }
          })
          x._strokeWeight = 2;
          x.stats = {downloadBps: 0, uploadBps: 0, error: false},

          this.routes.push(x);
        });

        this.refreshStats();
      }).catch(error => {
        dialogo.erroHttp(error);
      });
    },

    iconsForRoute() {
      return [
          {
            icon: this.lineSymbol,
            offset: "0",
            repeat: "20px",
          },
        ]
    },

    async refreshStats() {
      for (let i = 0; i < this.routes.length; i++) {
        const route = this.routes[i];

        await api.get(`/route-monitor/routes/${route.id}/stats`).then(res => {
          this.routes[i].stats = res.data;
        });
      }
    },

    toggleInfoWindow(route, event) {
      let downBps = bytes.formatBps(route.stats.downloadBps);
      let upBps = bytes.formatBps(route.stats.uploadBps);

      const errString = (!route.stats.error) ? '' : `<p class="mb-0 text-danger">Erro: ${route.stats.problemDescription}</p>`;
      const link = (!route.stats.zabbixLink) ? '' : `<p>Mais informações: <a href="${route.stats.zabbixLink}" target="_blank">Zabbix</a></p>`;

      const contentString =
          '<div id="content">' +
          '<div id="siteNotice">' +
          "</div>" +
          '<h6 style="color: '+ this.routeColor(route) +'">'+ this.routeLegibleStats(route) +'</h6>' +
          '<div id="bodyContent">' +
          '<p class="mb-0">Velocidade de <b><u>download</u></b>: '+ downBps +'.</p>' +
          '<p class="mb-0">Velocidade de <b><u>upload</u></b>: '+ upBps +'.</p>' +
          errString +
          '<br>' +
          link +
          "</div>" +
          "</div>";

      this.infoWindowContext.title = route.description;
      this.infoWindowContext.description = contentString;
      this.infoWindowContext.position = event.latLng;
      this.infoWindowContext.showInfo = true;
    },

    mouseOver(route) {
      route._strokeWeight = 10;
      this.$forceUpdate();
    },

    mouseOut(route) {
      route._strokeWeight = 2;
      this.$forceUpdate();
    },

    routeColor(route) {
      if (!route.active) {
        return '#888';
      }

      if (route.stats.error) {
        return '#FF0000';
      }

      if (route.stats.downloadBps === 0 && route.stats.uploadBps === 0) {
        return '#888';
      }

      if (route.downloadThresholdMbps && route.stats.downloadBps > (route.downloadThresholdMbps * 1024 * 1024)) {
        return '#fffe00';
      }
      if (route.uploadThresholdMbps && route.stats.uploadBps > (route.uploadThresholdMbps * 1024 * 1024)) {
        return '#fffe00';
      }

      return '#0000FF';
    },

    routeLegibleStats(route) {
      if (!route.active) {
        return 'ROTA DESATIVADA';
      }

      if (route.stats.error) {
        return 'ROTA COM PROBLEMA';
      }

      if (route.stats.downloadBps === 0 && route.stats.uploadBps === 0) {
        return 'ROTA SEM TRÁFEGO';
      }

      return 'ROTA ATIVA';
    },


    ///////////////////////////////////////////
    // import routes
    importRoutesClick() {
      this.$refs.importRouteModal.open();
    },

    importRouteClosed() {
      // console.log('importRouteClosed');
    },
    // import routes
    ///////////////////////////////////////////

    ///////////////////////////////////////////
    // list routes
    configRoutesClick() {
      this.$refs.listRoutesModal.open();
    },

    listRouteClosed() {
      // console.log('listRouteClosed');
    },
    // list routes
    ///////////////////////////////////////////
  },
  async mounted() {
    await this.initMap();
    this.refreshRoutes();
    this.getDataInterval = setInterval(this.refreshStats, 30000);
  },
  destroyed() {
    clearInterval(this.getDataInterval);
  },
}
</script>

<style scoped>
#map {
  height: calc(100vh - 220px);
}
</style>