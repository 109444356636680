<script>
import clone from '@/modules/clone'
import api from '@/modules/api'
import loading from '@/modules/loading'
import dialogo from '@/modules/dialogo'

export default {
  name: "EditRouteModal",
  props: {
    closeFunc: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      route: undefined,
      integrations: [],
      zabbixHosts: [],
      zabbixItems: [],
      zabbixTriggers: [],
    }
  },
  methods: {
    async open(route) {
      this.route = clone(route);
      document.getElementById('btn-modal-route-monitor-edit-route-abre').click();

      if (route.zabbixIntegrationId) {
        await this.refreshZabbixHosts();
      }
      if (route.zabbixHostId) {
        await this.refreshZabbixItems();
        await this.refreshZabbixTriggers();
      }
    },

    close() {
      document.getElementById('btn-modal-route-monitor-edit-route-fecha').click();
    },

    save() {
      loading(true);
      api.put(`/route-monitor/routes`, this.route).then(() => {
        loading(false);
        this.close();
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    refreshIntegrations() {
      api.get(`/integrations/zabbix?paginate.cancel&active=true&orderBy[description]=asc`).then(res => {
        this.integrations = res.data.items;
      })
    },

    async refreshZabbixHosts() {
      this.zabbixHosts = [];
      if (!this.route.zabbixIntegrationId) {
        return;
      }

      loading(true);
      await api.get(`/integrations/zabbix/${this.route.zabbixIntegrationId}/hosts`).then(res => {
        loading(false);
        this.zabbixHosts = res.data;
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    async refreshZabbixItems() {
      this.zabbixItems = [];

      loading(true);
      await api.get(`/integrations/zabbix/${this.route.zabbixIntegrationId}/hosts/${this.route.zabbixHostId}/items`).then(res => {
        loading(false);
        this.zabbixItems = res.data;
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    async refreshZabbixTriggers() {
      this.zabbixTriggers = [];

      loading(true);
      await api.get(`/integrations/zabbix/${this.route.zabbixIntegrationId}/hosts/${this.route.zabbixHostId}/triggers`).then(res => {
        loading(false);
        this.zabbixTriggers = res.data;
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    ///////////////////////////
    // ui
    canSave() {
      switch (true) {
        case (!this.route):
        case (this.route.description.length < 3):
          return false;
      }

      return true;
    },

    btnSaveClick() {
      this.save();
    },

    canSelectHost() {
      switch (true) {
        case (!this.route.zabbixIntegrationId):
          return false
      }

      return true;
    },

    canSelectItem() {
      switch (true) {
        case (!this.route.zabbixIntegrationId):
        case (!this.route.zabbixHostId):
          return false
      }

      return true;
    },

    zabbixIntegrationIdChanged() {
      this.route.zabbixHostId = undefined;
      this.refreshZabbixHosts();
    },

    zabbixHostChanged() {
      this.route.zabbixItemId = undefined;
      this.refreshZabbixItems();
      this.refreshZabbixTriggers();
    },
    // ui
    ///////////////////////////

  },
  mounted() {
    document.getElementById('modal-route-monitor-edit-route').addEventListener('hide.bs.modal', this.closeFunc);
  },
  created() {
    this.refreshIntegrations();
  },
};
</script>

<style scoped>
.border-4px {
  border-left: 4px solid;
  /* border-radius: 4px; */
}
</style>

<template>
  <div>
    <!-- modal-route-monitor-edit-route -->
    <!-- Button trigger modal -->
    <button type="button" class="d-none" data-bs-toggle="modal" data-bs-target="#modal-route-monitor-edit-route" id="btn-modal-route-monitor-edit-route-abre">
      Launch
    </button>

    <!-- Modal -->
    <div class="modal fade" id="modal-route-monitor-edit-route" tabindex="-1" role="dialog" aria-labelledby="modalOnuInfo" aria-hidden="true">
      <div class="modal-dialog modal-xl modal-fullscreen-margin scroll" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              <font-awesome-icon :icon="['fas', 'pen']" fixed-width/>
              Editar rota
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div v-if="(route)" class="row g-0">
              <div class="col-12 mb-2">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" v-model="route.active" id="active">
                  <label class="form-check-label" for="active">
                    Ativo
                  </label>
                </div>
              </div>
              <div class="col-12 mb-2">
                <label for="description">Identificação</label>
                <input type="text" id="description" v-model="route.description" class="form-control">
              </div>
              <div class="col-12 mb-2">
                <label for="downloadThresholdMbps">Alerta download (mbps)</label>
                <div class="input-group mb-3">
                  <input type="number" id="downloadThresholdMbps" v-model.number="route.downloadThresholdMbps" class="form-control">
                  <span class="input-group-text">mbps</span>
                </div>
              </div>
              <div class="col-12 mb-2">
                <label for="uploadThresholdMbps">Alerta upload (mbps)</label>
                <div class="input-group mb-3">
                  <input type="number" id="uploadThresholdMbps" v-model.number="route.uploadThresholdMbps" class="form-control">
                  <span class="input-group-text">mbps</span>
                </div>
              </div>
              <div class="col-12 mb-2">
                <label for="zabbixIntegrationId">Integração</label>
                <select id="zabbixIntegrationId" v-model="route.zabbixIntegrationId" @change="zabbixIntegrationIdChanged()" class="form-select">
                  <option :value="0">Nenhuma</option>
                  <option v-for="integration in integrations" :key="integration.id" :value="integration.id">{{integration.description}}</option>
                </select>
              </div>
              <div class="col-12 mb-2">
                <label for="zabbixHostId">Host</label>
                <select id="zabbixHostId" v-model="route.zabbixHostId" @change="zabbixHostChanged()" :disabled="(!canSelectHost())" class="form-select">
                  <option :value="''">Nenhum</option>
                  <option v-for="host in zabbixHosts" :key="host.hostId" :value="host.hostId">{{host.name}}</option>
                </select>
              </div>
              <div class="col-12 mb-2">
                <label for="zabbixDownloadItemId">Item download</label>
                <select id="zabbixDownloadItemId" v-model="route.zabbixDownloadItemId" :disabled="(!canSelectItem())" class="form-select">
                  <option :value="''">Nenhum</option>
                  <option v-for="item in zabbixItems" :key="item.itemId" :value="item.itemId">{{item.name}}</option>
                </select>
              </div>
              <div class="col-12 mb-2">
                <label for="zabbixUploadItemId">Item upload</label>
                <select id="zabbixUploadItemId" v-model="route.zabbixUploadItemId" :disabled="(!canSelectItem())" class="form-select">
                  <option :value="''">Nenhum</option>
                  <option v-for="item in zabbixItems" :key="item.itemId" :value="item.itemId">{{item.name}}</option>
                </select>
              </div>
              <div class="col-12 mb-2">
                <label for="zabbixOnlineItemId">Trigger down</label>
                <select id="zabbixOnlineItemId" v-model="route.zabbixOnlineItemId" :disabled="(!canSelectItem())" class="form-select">
                  <option :value="''">Nenhum</option>
                  <option v-for="trigger in zabbixTriggers" :key="trigger.triggerId" :value="trigger.triggerId">{{trigger.description}}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" id="btn-modal-route-monitor-edit-route-fecha">Fechar</button>
            <button type="button" class="btn btn-primary" @click="btnSaveClick()" :disabled="(!canSave())">
              <font-awesome-icon :icon="['fas', 'check']" fixed-width/>
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- modal-route-monitor-edit-route -->
  </div>
</template>

