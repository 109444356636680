/**
 * @param {string} input
 */
 function formatBps(input) {
    switch (true) {
        case (input < 1024):
            return input +" bps";

        case (input < (1024 * 1024)):
            return (input / 1024).toFixed(2) +" kbps";

        case (input < (1024 * 1024 * 1024)):
            return (input / (1024 * 1024)).toFixed(2) +" mbps";

        default:
            return (input / (1024 * 1024 * 1024)).toFixed(2) +" gbps";
    }
}

export default formatBps