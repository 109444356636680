<script>
import api from '@/modules/api'
import loading from '@/modules/loading'
import dialogo from '@/modules/dialogo'

export default {
  name: "ImportRouteModal",
  props: {
    closeFunc: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      routes: undefined,
    }
  },
  methods: {
    open() {
      document.getElementById('btn-modal-route-monitor-import-routes-abre').click();
    },

    close() {
      document.getElementById('btn-modal-route-monitor-import-routes-fecha').click();
    },

    btnUploadHandle(){
      document.getElementById('import-route-upload-file').click();
    },
    fileChangeHandle(event) {
      let data = new FormData();
      data.append('file', event.target.files[0]);

      loading(true);
      api.post(`/route-monitor/kml-to-coordinates`, data).then(res => {
        loading(false);
        this.routes = [];
        res.data.forEach( x => {
          x._checked = false;
          this.routes.push(x);
        });
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    selectRoute(route) {
      route._checked = (!route._checked);
    },

    ///////////////////////////
    // ui
    canImport() {
      if (!this.routes) {
        return false;
      }

      return this.routes.some(x => x._checked);
    },

    async btnImportClick() {
      const routes = this.routes.filter(x => x._checked).map(x => {
        return {
          description: x.description,
          steps: x.steps,
        }
      });

      loading(true);
      for (let i = 0; i < routes.length; i++) {
        const x = routes[i];

        await api.post('/route-monitor/routes', x).then(() => {
        }).catch(error => {
          dialogo.erroHttp(error);
        });
      }

      loading(false);
      this.close();
    },
    // ui
    ///////////////////////////

  },
  mounted() {
    document.getElementById('modal-route-monitor-import-routes').addEventListener('hide.bs.modal', this.closeFunc);
  },
};
</script>

<style scoped>
.border-4px {
  border-left: 4px solid;
  /* border-radius: 4px; */
}
</style>

<template>
  <div>
    <!-- modal-route-monitor-import-routes -->
    <!-- Button trigger modal -->
    <button type="button" class="d-none" data-bs-toggle="modal" data-bs-target="#modal-route-monitor-import-routes" id="btn-modal-route-monitor-import-routes-abre">
      Launch
    </button>

    <!-- Modal -->
    <div class="modal fade" id="modal-route-monitor-import-routes" tabindex="-1" role="dialog" aria-labelledby="modalOnuInfo" aria-hidden="true">
      <div class="modal-dialog modal-xl modal-fullscreen-margin scroll" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              <font-awesome-icon :icon="['fas', 'file-import']" fixed-width/>
              Importar rotas
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="d-grid gap-2">
              <input type="file" @change="fileChangeHandle" id="import-route-upload-file" accept=".kml" class="d-none">
              <button v-on:click="btnUploadHandle()" type="button" class="btn btn-secondary">
                Escolher um arquivo .KML <font-awesome-icon :icon="['fas', 'upload']" fixed-width/>
              </button>
            </div>
              <div v-if="(routes !== undefined && routes.length === 0)" class="col-12">
                <span class="text-danger">* Nenhuma rota foi encontrada nesse arquivo</span>
              </div>

            <div v-for="route in routes" :key="route.description" @click="selectRoute(route)" class="mt-2 g-0 card border-left border-secondary border-4px cursor-pointer">
              <div class="row g-0">
                <div class="col-auto px-2">
                  <input class="form-check-input" v-model="route._checked" type="checkbox">
                </div>
                <div class="col">
                  <p class="mb-0"><strong>Nome:</strong> {{ route.description }}</p>
                  <p class="mb-0">{{ route.steps.length }} marcações</p>
                </div>

              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" id="btn-modal-route-monitor-import-routes-fecha">Fechar</button>
            <button type="button" class="btn btn-primary" @click="btnImportClick()" :disabled="(!canImport())">
              <font-awesome-icon :icon="['fas', 'file-import']" fixed-width/>
              Importar
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- modal-route-monitor-import-routes -->
  </div>
</template>

